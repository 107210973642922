import React from "react"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown";
import excerptAst from "mdast-excerpt"
import TransitionLink from "gatsby-plugin-transition-link";
import {Power2, TweenLite} from "gsap";
import scrollTo from "gatsby-plugin-smoothscroll";

const ArticleCard = ({ article }) => {

    const MarkdownExcerpt = ({ source, pruneLength}) => (
        <ReactMarkdown
            source={source}
            disallowedTypes={['heading' , 'image']}
            astPlugins={[ast => excerptAst(ast, { pruneLength, truncate: true, })]}
        />
    )

    const TRANSITION_LENGTH = .5

    const exitTransitionUp = {
        length: TRANSITION_LENGTH,
        trigger: () =>  animateExitPageUp(),
    }

    const entryTransition = {
        delay: TRANSITION_LENGTH,
        trigger: () => animateEntryPage(),
    }

    function animateExitPageUp() {

        let html = document.querySelector("html");
        let mainLayout = document.querySelector('.main');
        let footer = document.querySelector('.footer');

        html.classList.add("smoothScrollOff")
        mainLayout.classList.add("invisible")

        TweenLite.to(
            mainLayout,
            .8,
            {
                opacity: 0,
                ease: Power2.easeInOut,
            }
        );

        TweenLite.to(
            footer,
            .4,
            {
                opacity: 0,
                ease: Power2.easeInOut,
            }
        );

        setTimeout(() => {
            scrollTo('#home')
        }, 450);
    }

    function animateEntryPage() {

        let html = document.querySelector("html");

        setTimeout(() => {
            html.classList.remove("smoothScrollOff")
        }, 120);
    }

    return (
        <section className="articles-section">
            <div className="articles-dynamic-content">

                <div className="articles-img-block">
                    <TransitionLink
                        className="item-link"
                        to={`/blog/${article.node.blog_category.slug}/${article.node.slug}`}
                        exit={exitTransitionUp}
                        entry={entryTransition}
                    >
                        <Img className="main-img"
                             fluid={article.node.image.childImageSharp.fluid}
                             alt={article.node.title}
                        />
                    </TransitionLink>
                    <div className="articles-img-details">
                        <TransitionLink
                            className="item-link"
                            to={`/blog/${article.node.blog_category.slug}`}
                            exit={exitTransitionUp}
                            entry={entryTransition}
                        >
                            <span className="article-img-category">
                              <img src={article.node.blog_category.icon.publicURL} alt="{article.node.blog_category.name}"/>
                              <span>{article.node.blog_category.name}</span>
                            </span>
                        </TransitionLink>
                        <span className="article-date">{article.node.published_at}</span>
                    </div>
                </div>

                <div className="articles-content">
                    <TransitionLink
                        className="item-link"
                        to={`/blog/${article.node.blog_category.slug}/${article.node.slug}`}
                        exit={exitTransitionUp}
                        entry={entryTransition}
                    >
                        <h2>{article.node.title}</h2>
                    </TransitionLink>
                    <MarkdownExcerpt source={article.node.content} pruneLength={178} />
                    <TransitionLink
                        className="btn-cta"
                        to={`/blog/${article.node.blog_category.slug}/${article.node.slug}`}
                        exit={exitTransitionUp}
                        entry={entryTransition}
                    >
                        Read more
                    </TransitionLink>
                </div>

            </div>
        </section>
    )
};

export default ArticleCard
