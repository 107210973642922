import React, {useEffect, useRef, createRef} from "react"
import ArticleCard from "./articleCard"
import { gsap } from "gsap";

const Articles = ({ articles, exitTransitionUp, entryTransition }) => {

    useEffect(() => {

        const cards = articleCard.current.map(card => card.current);
        gsap.from([cards], 0.4, {
            opacity: 0,
            y:120,
            stagger:  .15,
            delay:0.2
        });


    }, []);

    const articleCard = useRef(articles.map(() => createRef()));

    return (
        <div className="news-wrapper">
            {articles.map((article, i) => {
                return (
                    <div key={`article__${article.node.slug}`} ref={articleCard.current[i]}>
                        <ArticleCard article={article} exitTransition={exitTransitionUp} entryTransition={entryTransition}/>
                    </div>
                )
            })}
        </div>
    )
}

export default Articles
