import React, { useEffect, useRef } from "react"
import {graphql} from "gatsby"

import Index from "../components/layout"
import Articles from "../components/articles/articles";
import Tooltip from "../components/paginatorTooltip";
import TransitionLink from "gatsby-plugin-transition-link";
import scrollTo from "gatsby-plugin-smoothscroll";

import {gsap, Power2, TweenLite} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const query = graphql`
  query blog_category($slug: String!) {
    articles: allStrapiArticle(filter: {blog_category: { slug: {eq: $slug}}, active: {eq: true}}, sort: {order: DESC, fields: published_at}) {
      edges {
        node {
          title
          slug
          published_at
          content
          blog_category{
            name
            slug
            icon {
              publicURL
            }
          }
          image {
            childImageSharp {
                fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
          }
        }
      }
    }
    category: strapiBlogCategories(slug: { eq: $slug }) {
      name
      slug
      page_title
      icon {
              publicURL
            }
    }
  }
`

const BlogCategory = ({ data, pageContext }) => {

  const articles = data.articles.edges;
  const category = data.category;
  const {prev, next} = pageContext;

  let blogCategoryTitle = useRef(null);
  let blogCategorySubTitle = useRef(null);

  useEffect(() => {
    gsap.from(blogCategoryTitle, 0.4, {
      opacity: 0,
      delay: 0.1
    });
    gsap.from(blogCategorySubTitle, 0.4, {
      opacity: 0,
      delay: 0.2
    });
  }, [blogCategoryTitle, blogCategorySubTitle]);

  const TRANSITION_LENGTH = .5

  const exitTransition = {
    length: TRANSITION_LENGTH,
    trigger: () =>  animateExitPage(),
  }

  const exitTransitionUp = {
    length: TRANSITION_LENGTH,
    trigger: () =>  animateExitPageUp(),
  }

  const exitReverseTransition = {
    length: TRANSITION_LENGTH,
    trigger: () =>  animateExitPageReverse(),
  }

  const entryTransition = {
    delay: TRANSITION_LENGTH,
    trigger: () => animateEntryPage(),
  }

  function animateExitPage() {

    let html = document.querySelector("html");
    let mainLayout = document.querySelector('.main');
    let footer = document.querySelector('.footer');

    html.classList.add("smoothScrollOff")
    mainLayout.classList.add("invisible")

    TweenLite.to(
        mainLayout,
        .8,
        {
          opacity: 0,
          x: '60%',
          ease: Power2.easeInOut,
        }
    );

    TweenLite.to(
        footer,
        .4,
        {
          opacity: 0,
          ease: Power2.easeInOut,
        }
    );

    setTimeout(() => {
      scrollTo('#home')
    }, 450);
  }

  function animateExitPageUp() {

    let html = document.querySelector("html");
    let mainLayout = document.querySelector('.main');
    let footer = document.querySelector('.footer');

    html.classList.add("smoothScrollOff")
    mainLayout.classList.add("invisible")

    TweenLite.to(
        mainLayout,
        .8,
        {
          opacity: 0,
          y: '60%',
          ease: Power2.easeInOut,
        }
    );

    TweenLite.to(
        footer,
        .4,
        {
          opacity: 0,
          ease: Power2.easeInOut,
        }
    );

    setTimeout(() => {
      scrollTo('#home')
    }, 450);
  }

  function animateExitPageReverse() {

    let html = document.querySelector("html");
    let mainLayout = document.querySelector('.main');
    let footer = document.querySelector('.footer');

    html.classList.add("smoothScrollOff")
    mainLayout.classList.add("invisible")

    TweenLite.to(
        mainLayout,
        .8,
        {
          opacity: 0,
          x: '-60%',
          ease: Power2.easeInOut,
        }
    );

    TweenLite.to(
        footer,
        .4,
        {
          opacity: 0,
          ease: Power2.easeInOut,
        }
    );

    setTimeout(() => {
      scrollTo('#home')
    }, 450);
  }

  function animateEntryPage() {

    let html = document.querySelector("html");

    setTimeout(() => {
      html.classList.remove("smoothScrollOff")
    }, 120);
  }

  return (
    <Index
      title={data.category.name}
      description={"Check out the latest articles from the" + data.category.name + "section"}
    >
      <div className="regular-section blog-category">
        <div ref={el => (blogCategorySubTitle = el)} className="category-title">
          <span>
            <img src={category.icon.publicURL} alt="{article.node.blog_category.name}"/>
            <h2>{category.name}</h2>
          </span>
        </div>

        <Articles articles={articles} exitTransition={exitTransition} exitTransitionUp={exitTransitionUp}/>

      </div>

      <section className="regular-section more-collections blog-collections">
        <h2>Other Categories</h2>
        <div className="paginator-big">

          {prev &&
          <Tooltip text={ prev.name}>
            <TransitionLink
                className="read-more"
                to={"/blog/" + prev.slug}
                exit={exitTransition}
                entry={entryTransition}
            >
              <i className="icons icon-west"/>
              <span className="collection-number">Prev Category</span>
            </TransitionLink>
          </Tooltip>
          }

          {next &&
          <Tooltip text={ next.name}>
            <TransitionLink
                className="read-more"
                to={"/blog/" + next.slug}
                exit={exitReverseTransition}
                entry={entryTransition}
            >
              <span className="collection-number">Next Category</span>
              <i className="icons icon-east"/>
            </TransitionLink>
          </Tooltip>
          }

        </div>
      </section>
    </Index>
  )
}

export default BlogCategory
